<template>
  <div>
    <CCard>
      <CCardHeader>
        {{ $t("role.dashboard.common.planning.title") }}
        <div class="card-header-actions">
          <a
            href=""
            class="card-header-action"
            rel="noreferrer noopener"
            target="_blank"
            @click.prevent="getData()"
          >
            <small class="text-muted">{{
              $t("role.dashboard.common.planning.refresh")
            }}</small>
          </a>
        </div>
      </CCardHeader>
      <CCardBody>
        <div class="d-flex justify-content-center" v-if="loading">
          <CSpinner color="info" />
        </div>
        <div v-else>
          <CAlert
            show
            :color="data.class"
            v-for="(data, index) in datasetFiltered"
            :key="index"
            :v-html="data.title"
          >
          </CAlert>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew, ApiCall } from "../../ds/index";
import moment from "moment";

function addDays(days) {
  var result = new Date();
  result.setDate(result.getDate() + days);
  return result;
}

export default {
  data() {
    return {
      loading: false,
      dataset: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    // whenever question changes, this function will run
    roleId(newRoleId, oldRoleId) {
      if (newRoleId != oldRoleId) {
        this.getData();
      }
    },
  },
  methods: {
    getData() {
      const self = this;
      this.loading = true;
      let date_start, date_end;
      date_start = addDays(0);
      date_end = addDays(+60);
      const roleId = this.roleId;
      const dm = GetDataManagerNew("role_planningcalendar", [
        this.$store.state.role.id,
        date_start.valueOf(),
        date_end.valueOf(),
      ]);

      ApiCall(
        dm,
        new Query(),
        (response) => {
          self.loading = false;
          self.dataset = response.result;
        },
        (response) => {
          self.loading = false;
        }
      );
    },
  },
  computed: {
    roleId() {
      return this.$store.state.role.id;
    },
    datasetFiltered() {
      let list_dates = [];
      const i18n = this.$i18n;
      this.dataset.forEach(function (data, index) {
        // console.log(data, index);
        if (data.e_type == "P") {
          list_dates.push({
            title:
              "<b>" +
              moment(data.startDate).locale("it").format("L") +
              "</b> " +
              data.title +
              " " +
              i18n.t("role.dashboard.producer.timeAt") +
              " " +
              moment(data.startDate).locale("it").format("LT"),
            startDate: data.startDate,
            class: "primary",
          });
        } else if (data.e_type == "C") {
          list_dates.push({
            title:
              "<b>" +
              moment(data.startDate).locale("it").format("L") +
              "</b> " +
              data.title +
              " " +
              i18n.t("role.dashboard.producer.finishedAt") +
              " + " +
              moment(data.endDate).locale("it").format("L"),
            startDate: data.startDate,
            class: "danger",
          });
        }
      });
      return list_dates.sort((a, b) => a.startDate - b.startDate).slice(0, 5);
    },
  },
};
</script>
